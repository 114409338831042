import React, { useState } from 'react';
import HamburgIcon from '../../assets/images/hamburg.svg';
import Button from '../Button';
import SelectCountry from './SelectCountry';

const MobileMenu = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div>
      <div
        className='cursor-pointer'
        onClick={() => setOpenMenu(!openMenu)}
      >
        <img src={HamburgIcon} alt="" className='w-15px h-3.5' />
      </div>
      {openMenu && (
        <>
          <div className='absolute bg-primary top-20 left-0 w-full text-15 px-6.5 pb-10'>
            <div className='flex justify-center py-2'>
              <a href='/' className='text-green-primary border-b border-green-primary py-1'>
                Farm
              </a>
            </div>
            <div className='flex justify-center py-2'>
              <a href='/migration' className='text-gray-light py-1'>
                Migration
              </a>
            </div>
            <div className='flex justify-center py-2'>
              <a href='http://docs.betero.io/' className='text-gray-light py-1'>
                FAQ
              </a>
            </div>
            <div className='flex justify-center py-2'>
              <a href='https://betero.games/' className='text-gray-light py-1'>
                App
              </a>
            </div>
            <div className='flex justify-center py-2'>
              <a href='https://www.betero.io/Betero_Litepaper.pdf' className='text-gray-light py-1'>
                Litepaper
              </a>
            </div>
            <div className='flex justify-center py-2 w-full'>
              <Button
                type='contained'
                className='h-11 my-2 text-center self-center w-[9rem]'
                url='https://betero.games/'
              >
                Play Now
              </Button>
            </div>
            <div>
              <SelectCountry />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MobileMenu;