import { useState, useEffect, useMemo } from 'react';

const API_BASE_URL = 'https://ks-setting.kyberswap.com/api/v1/tokens';
const EXCLUDED_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
const PAGE_SIZE = 100;

const useKyberTokenList = (chainId) => {
    const [tokens, setTokens] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const urls = useMemo(() => ({
        notWhitelisted: `${API_BASE_URL}?page=1&pageSize=${PAGE_SIZE}&isWhitelisted=false&chainIds=${chainId}`,
        whitelisted: `${API_BASE_URL}?page=1&pageSize=${PAGE_SIZE}&isWhitelisted=true&chainIds=${chainId}`
    }), [chainId]);

    useEffect(() => {
        const fetchTokens = async () => {
            try {
                setLoading(true);
                setError(null);

                const [notWhitelistedRes, whitelistedRes] = await Promise.all([
                    fetch(urls.notWhitelisted),
                    fetch(urls.whitelisted)
                ]);

                if (!notWhitelistedRes.ok || !whitelistedRes.ok) {
                    throw new Error('Failed to fetch token lists');
                }

                const [notWhitelistedData, whitelistedData] = await Promise.all([
                    notWhitelistedRes.json(),
                    whitelistedRes.json()
                ]);

                const allTokens = [...whitelistedData.data.tokens, ...notWhitelistedData.data.tokens];
                const uniqueTokens = Object.values(
                    Object.fromEntries(
                        allTokens
                            .filter(token => token.address !== EXCLUDED_ADDRESS)
                            .map(token => [token.address, token])
                    )
                );

                setTokens(uniqueTokens);
            } catch (err) {
                setError(err.message);
                console.error("Failed to fetch tokens:", err);
            } finally {
                setLoading(false);
            }
        };

        if (chainId) {
            fetchTokens();
        }

        return () => {
            setTokens([]);
            setLoading(true);
            setError(null);
        };
    }, [chainId, urls]);

    return { tokens, loading, error };
};

export default useKyberTokenList;