import React, { useEffect, useState, useRef, useMemo } from 'react';

import { useWeb3React } from '@web3-react/core';

import { Widget } from "@kyberswap/widgets";

import WalletPopup from '../WalletPopup';
import Button from '../Button';
import '../../assets/css/global.scss';

import useKyberTokenList from '../../hooks/useKyberSwapTokenList';
import Spinner from '../Spinner';
import './kyberswap.css'

const DEFAULT_TOKEN_OUT = '0x7c33D9c9685408645486497c708ab491402e0886';
const WIDGET_CONFIG = {
    enableRoute: true,
    showDetail: true,
    showRate: true
};

export function KyberSwapWidget({ openBuyPopup, setOpenPopup }) {
    const { account, provider, chainId } = useWeb3React();
    const { tokens, loading, error } = useKyberTokenList(chainId);
    const popupRef = useRef(null);
    const [openWalletPopup, setOpenWalletPopup] = useState(false);

    const theme = useMemo(() => ({
        primary: '#1C1C1C',
        secondary: '#0F0F0F',
        dialog: '#313131',
        borderRadius: '20px',
        buttonRadius: '24px',
        stroke: '#505050',
        interactive: '#212121',
        accent: '#63C127',
        success: '#189470',
        warning: '#F1B02F',
        error: '#E93424',
        text: '#FFFFFF',
        subtext: '#A9A9A9',
        fontFamily: 'Poppins',
    }), []);

    const feeSetting = useMemo(() => ({
        feeAmount: 25,
        feeReceiver: "0x5f695E11e3f6b0Ea6ad1141738B1635a24d6c990",
        chargeFeeBy: "currency_out",
        isInBps: true,
    }), []);

    useEffect(() => {
        if (!tokens || loading) return;

        const tokenButtons = document.querySelectorAll('img[alt="tokenIn"], img[alt="tokenOut"]');
        tokenButtons.forEach(el => el.parentElement.classList.add('kyberswaptokenContainer'));
    }, [tokens, loading]);

    useEffect(() => {
        if (!openBuyPopup) return;

        document.body.style.overflow = "hidden";
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopup(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.removeProperty("overflow");
        };
    }, [openBuyPopup, setOpenPopup]);

    useEffect(() => {
        setOpenWalletPopup(!account);
    }, [account]);

    const renderContent = useMemo(() => {
        if (openWalletPopup) {
            return <WalletPopup setOpenPopup={setOpenPopup} />;
        }
        if (!account) return null;
        if (loading) return <Spinner />;
        if (error) {
            return (
                <Button className='h-50px'>
                    Error loading tokens! Try refreshing the page and try again.
                </Button>
            );
        }
        if (tokens) {
            return (
                <Widget
                    client="Betero"
                    theme={theme}
                    tokenList={tokens}
                    provider={provider}
                    defaultTokenOut={DEFAULT_TOKEN_OUT}
                    feeSetting={feeSetting.feeAmount && feeSetting.feeReceiver ? feeSetting : undefined}
                    {...WIDGET_CONFIG}
                />
            );
        }
        return null;
    }, [openWalletPopup, account, loading, error, tokens, theme, provider, feeSetting, setOpenPopup]);

    return (
        <div className="popup-container">
            <div ref={popupRef} className='KyberSwapWidget'>
                {renderContent}
            </div>
        </div>
    );
}

export default KyberSwapWidget;
